@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: rgb(240, 253, 244);
}

.swiper-button-next-after,
.swiper-button-prev-after{
    color: #a8dadc;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
    background: #a8dadc !important;
}

html::-webkit-scrollbar{
    display: none;
}

html{
    scrollbar-width: none;
}